<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - 再訪問受注率</h1>
    </header>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="condition.department_id"
                    :options="options_department"
                    empty_option="全て"
                ></form-select>
            </div>
            <div class="col-6">
                <label class="form-label">部門</label>
                <form-select
                    v-model="condition.division"
                    :options="Division.options()"
                    empty_option="全て"
                    @selected="resetDepartment()"
                ></form-select>
            </div>
            <div class="col-6">
                <label class="form-label">営業マン所属事業部</label>
                <form-select
                    v-model="condition.sales_department_id"
                    :options="options_department"
                    :empty_option="condition.division === Division.FIELD_SALES ? '全て' : ''"
                    :disabled="condition.division === Division.FIELD_SALES ? false : true"
                ></form-select>
            </div>
            <div class="col-6">
                <label class="form-label">営業担当</label>
                <form-select-search-multiple
                    v-model="condition.sales_user_ids"
                    :options="options_user"
                    empty_option="全て"
                ></form-select-search-multiple>
            </div>
            <div class="col-3">
                <label class="form-label">対象年1</label>
                <form-select
                    v-model="year_1"
                    :options="options_year"
                ></form-select>
            </div>
            <div class="col-3">
                <label class="form-label">対象年2</label>
                <form-select
                    v-model="year_2"
                    :options="options_year"
                    empty_option="なし"
                ></form-select>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="table-responsive" style="width: 100%;">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr class="table-dark text-center">
                        <th colspan="2">&nbsp;</th>
                        <template v-for="row of stats_1" :key="row.month">
                            <th>{{ row.month }}月</th>
                        </template>
                        <th>合計</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center">
                        <td class="table-light" rowspan="3">{{ year_1 }}年</td>
                        <td class="table-light">再訪問数</td>
                        <template v-for="row of stats_1" :key="row.month">
                            <td v-if="row.revisited_count === null">-</td>
                            <td v-else-if="row.revisited_count > 0">
                                <button class="btn btn-link p-0" @click="showProjects(row.revisited_ids)">{{ row.revisited_count }}</button>
                            </td>
                            <td v-else>{{ row.revisited_count }}</td>
                        </template>
                        <td>{{ stats_1_revisited_total_count }}</td>
                    </tr>
                    <tr class="text-center">
                        <td class="table-light">受注数</td>
                        <template v-for="row of stats_1" :key="row.month">
                            <td v-if="row.ordered_count === null">-</td>
                            <td v-else-if="row.ordered_count > 0">
                                <button class="btn btn-link p-0" @click="showProjects(row.ordered_ids)">{{ row.ordered_count }}</button>
                            </td>
                            <td v-else>{{ row.ordered_count }}</td>
                        </template>
                        <td>{{ stats_1_ordered_total_count }}</td>
                    </tr>
                    <tr class="text-center">
                        <td class="table-light">受注率</td>
                        <template v-for="row of stats_1" :key="row.month">
                            <td>{{ row.rate !== null ? row.rate.toFixed(2) + "%" : "-" }}</td>
                        </template>
                        <td>{{ stats_1_total_rate }}%</td>
                    </tr>
                    <template v-if="year_2">
                        <tr class="text-center">
                            <td class="table-light" rowspan="3">{{ year_2 }}年</td>
                            <td class="table-light">再訪問数</td>
                            <template v-for="row of stats_2" :key="row.month">
                                <td v-if="row.revisited_count === null">-</td>
                                <td v-else-if="row.revisited_count > 0">
                                    <button class="btn btn-link p-0" @click="showProjects(row.revisited_ids)">{{ row.revisited_count }}</button>
                                </td>
                                <td v-else>{{ row.revisited_count }}</td>
                            </template>
                            <td>{{ stats_2_revisited_total_count }}</td>
                        </tr>
                        <tr class="text-center">
                            <td class="table-light">受注数</td>
                            <template v-for="row of stats_2" :key="row.month">
                                <td v-if="row.ordered_count === null">-</td>
                                <td v-else-if="row.ordered_count > 0">
                                    <button class="btn btn-link p-0" @click="showProjects(row.ordered_ids)">{{ row.ordered_count }}</button>
                                </td>
                                <td v-else>{{ row.ordered_count }}</td>
                            </template>
                            <td>{{ stats_2_ordered_total_count }}</td>
                        </tr>
                        <tr class="text-center">
                            <td class="table-light">受注率</td>
                            <template v-for="row of stats_2" :key="row.month">
                                <td>{{ row.rate !== null ? row.rate.toFixed(2) + "%" : "-" }}</td>
                            </template>
                            <td>{{ stats_2_total_rate }}%</td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <p>再訪問数は、各月の2回目以降の訪問が行われた案件数。<br>
                受注数は、各月の受注した数です。<br>
            部門、営業担当は、現時点のものです。</p>
        </div>
    </section>

    <div class="modal-backdrop show" v-if="modal_show"></div>
    <div class="modal fade show" tabindex="-1"  style="display:block;" v-if="modal_show">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">該当案件一覧</h3>
                    <button type="button" class="btn-close" @click="modal_show = false"></button>
                </div>
                <div class="modal-body mb-5">
                    <inline-loader v-if="modal_loading"></inline-loader>
                    <div v-else-if="modal_projects_list.length">
                        <table class="table table-sm">
                            <thead>
                                <tr class="table-dark table-head">
                                    <th></th>
                                    <th class="text-center w-ms">会社名</th>
                                    <th class="text-center w-ss">客先担当者</th>
                                    <th class="text-center w-ms">事業部</th>
                                    <th class="text-center w-ss">営業担当</th>
                                    <th class="text-center w-ss">ステータス</th>
                                    <th class="text-center w-ss">評価</th>
                                    <th class="text-center w-mm">規格</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="project in modal_projects_list" :key="project.project_id">
                                    <td @click.stop>
                                        <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i></router-link>
                                    </td>
                                    <td>{{ project.customer.company_name }}</td>
                                    <td>{{ project.primary_contact ? project.primary_contact.contact_name : '' }}</td>
                                    <td>{{ project.department ? project.department.department_name : '' }}</td>
                                    <td>{{ project.sales_user ? project.sales_user.user_name : '' }}</td>
                                    <td>{{ project.status_label }}</td>
                                    <td>{{ project.rank_label }}</td>
                                    <td>{{ project.standards_label_all }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else>
                        <p class="alert alert-secondary">該当案件が見つかりません</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormSelect from '@/components/forms/FormSelect';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Department from '@/models/entities/department';
import Project from '@/models/entities/project';
import User from '@/models/entities/user';
import Division from '@/models/enums/division';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';

export default {
    name: 'PageContract',
    components: {
        InlineLoader,
        FormSelect,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],
            options_user: [],
            options_year: [],

            // 検索条件
            condition: {
                department_id: null,
                division: null,
                sales_department_id: null,
                sales_user_ids: [],
            },
            year_1: null,
            year_2: null,

            // 表示データ
            stats_1: [],
            stats_2: [],

            // モーダル用
            modal_show: false,
            modal_loading: false,
            modal_projects_list: [],

            Division,
        }
    },
    mounted() {
        this.fetchDepartments();
        this.fetchUsers();
        this.initConditionYear();
    },
    methods: {
        fetchDepartments() {
            this.loading_count++;

            this.options_department.splice(0);

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                // 事業部初期値セット
                if (this.$store.state.auth.department_id) {
                    this.condition.department_id = this.$store.state.auth.department_id;
                } else {
                    this.condition.department_id = this.options_department[0].value;
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        fetchUsers() {
            this.loading_count++;

            this.options_user.splice(0);

            this.$http.get('/user')
            .then(response => {
                for (let row of response.data) {
                    let user = new User(row);
                    this.options_user.push({value: user.user_id, label: user.user_name});
                }
            })
            .finally(() => {
                this.loading_count--;
            });
        },
        // 検索
        search() {
            this.loading_count++;
            this.stats_1.splice(0);

            this.$http.get(`/report/revisit-contract/${this.year_1}`, {
                params: this.condition,
            })
            .then(response => {
                this.stats_1 = response.data;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            });

            if (this.year_2) {
                this.loading_count++;
                this.stats_2.splice(0);

                this.$http.get(`/report/revisit-contract/${this.year_2}`, {
                    params: this.condition,
                })
                .then(response => {
                    this.stats_2 = response.data;
                })
                .finally(() => {
                    // ローディング終了
                    this.loading_count--;
                });
            }
        },
        initConditionYear() {
            let today = new Date();
            let this_year = today.getFullYear();
            for (let y = this_year; y > this_year - 10; y--) {
                this.options_year.push({value: y, label: `${y}年`});
            }

            this.year_1 = this_year;
        },
        showProjects(project_ids) {
            this.modal_loading = true;
            this.modal_show = true;

            this.$http.get('/project/ids', {
                params: {project_ids},
            })
            .then((response) => {
                this.modal_projects_list.splice(0);
                for (let row of response.data) {
                    this.modal_projects_list.push(new Project(row));
                }
            })
            .finally(() => {
                this.modal_loading = false;
            })
        },
        resetDepartment() {
            if (this.condition.division !== Division.FIELD_SALES) {
                this.condition.sales_department_id = null;
            }
        },
    },

    computed: {
        stats_1_revisited_total_count() {
            let revisited = 0;
            for(const row of this.stats_1) {
                revisited += row.revisited_count
            }

            return revisited;
        },
        stats_1_ordered_total_count() {
            let ordered = 0;
            for(const row of this.stats_1) {
                ordered += row.ordered_count
            }

            return ordered;
        },
        stats_1_total_rate() {
            let total_rate = 0;
            if (this.stats_1_revisited_total_count && this.stats_1_ordered_total_count) {
                total_rate = (this.stats_1_ordered_total_count/this.stats_1_revisited_total_count*100).toFixed(2)
            }

            return total_rate;
        },
        stats_2_revisited_total_count() {
            let revisited = 0;
            for(const row of this.stats_2) {
                revisited += row.revisited_count
            }

            return revisited;
        },
        stats_2_ordered_total_count() {
            let ordered = 0;
            for(const row of this.stats_2) {
                ordered += row.ordered_count
            }

            return ordered;
        },
        stats_2_total_rate() {
            let total_rate = 0;
            if (this.stats_2_revisited_total_count && this.stats_2_ordered_total_count) {
                total_rate = (this.stats_2_ordered_total_count/this.stats_2_revisited_total_count*100).toFixed(2);
            }

            return total_rate;
        }
    }
}
</script>
